import SettingsMenu from "@/components/settings/SettingsMenu";
import Avatar from "@/components/ui/Avatar";
import { uiStore } from "@/stores/uiStore";
import { useStore } from "@nanostores/react";

export default function SettingsButton() {
  const user = useStore(uiStore.user);
  if (!user) return null;
  return (
    <SettingsMenu className="p-1 hover:bg-gray-100/50 rounded-md cursor-pointer">
      <Avatar user={user} className="w-8 h-8" tooltip="Settings" />
    </SettingsMenu>
  );
}
