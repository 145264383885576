import Modal, { ModalBody, ModalButton } from "@/components/modals/Modal";
import { classNames } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

type Props = {};

export default function ConfirmModal(props: Props) {
  const confirmProps = useStore(uiStore.showConfirmModal);
  const open = !!confirmProps;

  if (!open) return null;

  const setOpen = (open: boolean) => {
    if (!open) uiStore.showConfirmModal.set(undefined);
  };

  const type = confirmProps?.type || "info";

  const Icon =
    type == "info" ? QuestionMarkCircleIcon
    : type == "warning" ? ExclamationCircleIcon
    : XMarkIcon;

  const buttons = (
    <>
      {confirmProps?.onClick && (
        <ModalButton
          onClick={() => {
            void confirmProps?.onClick?.();
            setOpen(false);
          }}
          className={
            type == "info" ? "bg-blue-600 hover:bg-blue-800"
            : type == "warning" ?
              "bg-yellow-600 hover:bg-yellow-800"
            : "bg-red-600 hover:bg-red-800"
          }
        >
          OK
        </ModalButton>
      )}
      <ModalButton
        onClick={() => {
          setOpen(false);
        }}
        className="bg-gray-600 hover:bg-gray-800"
      >
        {!confirmProps?.onClick ? "Done" : "Cancel"}
      </ModalButton>
    </>
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalBody
        title={confirmProps?.title || ""}
        icon={
          <Icon
            className={classNames(
              "w-6 h-6",
              type == "info" ? "text-blue-600"
              : type == "warning" ? "text-yellow-600"
              : "text-red-600",
            )}
          />
        }
        iconBg={
          type == "info" ? "bg-blue-100"
          : type == "warning" ?
            "bg-yellow-100"
          : "bg-red-100"
        }
        buttons={buttons}
        className="sm:min-w-[500px]"
      >
        {confirmProps?.subtitle && (
          <p className="mt-2 text-gray-600 whitespace-pre-wrap">{confirmProps.subtitle}</p>
        )}
        {confirmProps?.body ?
          <div className="mt-4">{confirmProps.body}</div>
        : null}
      </ModalBody>
    </Modal>
  );
}
