import { HTMLAttributes } from "react";

function Logo({ fill = "blue", ...props }: { fill?: string } & HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 128 128"
        xmlSpace="preserve"
      >
        <path
          d="M88 129H1V1h128v128H88m21-103.38v-4.374H57.248V41.1h31.856v31.645H109V25.62M21 74.501v34.216h51.715v-19.89H40.826v-31.54H21v17.214z"
          opacity="0"
        ></path>
        <path
          fill={fill}
          d="M109 26.11v46.636H89.104V41.101H57.248V21.246H109v4.864zM21 74.002V57.287h19.826v31.54h31.89v19.89H21V74.002z"
          opacity="1"
        ></path>
      </svg>
    </div>
  );
}

export default Logo;
