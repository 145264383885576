import { useRouter } from "next/router";
import { PropsWithChildren, useState } from "react";

import API from "@/client/api";
import { getSupportModalConfig } from "@/components/support/SupportButton";
import ConditionalLink from "@/components/ui/ConditionalLink";
import PopoverMenu, { PopoverOption } from "@/components/ui/PopoverMenu";
import { useErrorTracker } from "@/hooks/useErrorTracker";
import { useDevTools } from "@/hooks/useUIStore";
import { meetingStore } from "@/stores/meetingStore";
import { uiStore } from "@/stores/uiStore";
import { userMeta } from "@/types";
import { ArrowTopRightOnSquareIcon, CheckIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { toast } from "react-toastify";
import { twJoin } from "tailwind-merge";

type SettingsLink = {
  checked?: () => boolean;
  label: string;
  className?: string;
  href?: string;
  attrs?: Record<string, string>;
  onClick?: () => void;
};

const settings: SettingsLink[] = [
  {
    label: "Settings",
    href: "/settings",
  },
  {
    label: "Manage Calendars",
    href: "/settings/connect",
  },
  {
    label: "Get Chrome Extension",
    href: "https://chromewebstore.google.com/detail/distill/kidifhledoijjifmngjkaclhdoffdneg",
  },
  {
    label: "Log out",
    onClick: () => {
      uiStore.logOut();
    },
  },
];

const testErrorLink: SettingsLink = {
  label: "Trigger Test Error",
};

const adminLinks: SettingsLink[] = [
  {
    label: "User List",
    href: "/admin/users",
  },
  {
    label: "Invite List",
    href: "/admin/invites",
  },
  {
    label: "Email Resolutions",
    href: "/admin/emails",
  },
  {
    label: "Feature Flags",
    attrs: {
      "data-attr": "posthog-early-access-features-button",
      "data-tooltip-content": "Feature Flags",
      "data-tooltip-id": "tooltip",
    },
  },
  {
    label: "All Entities",
    href: "/admin/entities",
  },
  {
    label: "AI Playground",
    href: "/admin/playground",
  },
  {
    label: "Reset Onboarding",
    href: "/admin/reset_onboarding",
  },
  {
    label: "Dogfood Profiles",
    href: "/search?personal_connections=true&type=pe",
  },
  {
    label: "Profile Quality Dashboard",
    href: "/feedback/profile_quality",
  },
  {
    label: "Profile Review List",
    href: "/feedback/profile_reviews",
  },
  {
    label: "Global Cookies",
    href: "/admin/cookies",
  },
  {
    label: "Blocked Sources",
    href: "/admin/blocked_sources",
  },
  testErrorLink,
];

export default function SettingsMenu({
  className,
  children,
}: PropsWithChildren<{
  className?: string;
}>) {
  const errorTracker = useErrorTracker();
  const showDevTools = useDevTools();
  const debugCalendars = useStore(meetingStore.debugShowCalendars);
  useStore(meetingStore.debugFakeMeetings);
  const [showAdminLinks, setShowAdminLinks] = useState(false);
  const user = useStore(uiStore.user);

  const page = useRouter().pathname;
  const pageDevTools: SettingsLink[] = [];

  testErrorLink.onClick = () => {
    errorTracker.testConfig();
    toast.error("💣");
    API.axios.get("/admin/error").catch(() => {});
    errorTracker.sendError(new Error("heyo"));
  };

  if (showDevTools && (page == "/dashboard" || page == "/start" || page == "/schedule")) {
    pageDevTools.push({
      label: "[dashboard] Fake Meetings",
      onClick: () => {
        meetingStore.toggleFakeMeetings();
      },
      checked: () => meetingStore.debugFakeMeetings.get(),
    });
    debugCalendars.forEach((cal) => {
      pageDevTools.push({
        label: cal,
        onClick: () => {
          meetingStore.hideUserCalendar(cal);
        },
        checked: () => true,
      });
    });
    pageDevTools.push({
      label: "[dashboard] Add User Calendar",
      onClick: () => {
        meetingStore.showUserCalendar();
      },
    });
    pageDevTools.push({
      label: "-",
    });
  }

  const links: SettingsLink[] = [
    user ?
      ([
        {
          label: user.email,
        },
        {
          label: "-",
        },
      ] as SettingsLink[])
    : [],
    showDevTools ? [...pageDevTools, { label: "-" }] : [],
    userMeta(user).li_profile ?
      [
        {
          label: "View my profile",
          href:
            `/profile?url=` +
            encodeURIComponent(`https://www.linkedin.com/in/${userMeta(user).li_profile}`),
        },
      ]
    : [],
    settings,
    {
      label: "Contact Us",
      onClick: () => {
        uiStore.showInputModal.set(
          getSupportModalConfig(user, document.title, decodeURIComponent(window.location.href)),
        );
      },
    },
    showDevTools ?
      [
        { label: "-" },
        {
          label: `${showAdminLinks ? "Hide" : "Show"} Admin Links`,
          className: "bg-indigo-100 font-bold",
          onClick: () => setShowAdminLinks(!showAdminLinks),
        },
        ...(showAdminLinks ? adminLinks.map((a) => ({ className: "bg-indigo-50", ...a })) : []),
      ]
    : [],
  ].flat();

  return (
    <PopoverMenu buttonClass={className} buttonLabel={children} keepOpenOnClick>
      {links.map((link, i) =>
        link.label == "-" ?
          <div key={i} className="px-4 text-xs text-gray-500 border-t"></div>
        : <ConditionalLink
            href={link.href}
            onClick={link.onClick}
            target={link.href?.startsWith("http") ? "_blank" : undefined}
            rel="noopener noreferrer"
            key={i}
            {...link.attrs}
          >
            <PopoverOption className={twJoin("w-64 flex items-center", link.className)} key={i}>
              {link.label}
              {link.checked?.() && <CheckIcon className="w-4 h-4 ml-2" />}
              {link.href?.startsWith("http") && (
                <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2" />
              )}
            </PopoverOption>
          </ConditionalLink>,
      )}

      <div className="px-4 py-2 text-xs text-gray-500 border-t">
        Version: {process.env.GIT_HASH?.substring(0, 10)}
      </div>
    </PopoverMenu>
  );
}
