import Button, { ButtonLink } from "@/components/ui/Button";
import { logger } from "@/lib/logger";
import { PROD_CHROME_EXTENSION, uiStore } from "@/stores/uiStore";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

function checkForCookie() {
  if (document.visibilityState === "visible") {
    uiStore.checkCookieFragments({}, true);
  }
}

export default function CookieBanner() {
  const status = useStore(uiStore.liCookieStatus);
  const shouldShow = status != "unknown" && status != "valid";
  const posthog = usePostHog();

  useEffect(() => {
    if (shouldShow) {
      posthog?.capture("cookie-banner-shown", { status });
    }
  }, [shouldShow, posthog, status]);

  if (!shouldShow) return null;

  const listenForCookie = () => {
    posthog?.capture("cookie-banner-clicked", { status });
    document.removeEventListener("visibilitychange", checkForCookie);
    document.addEventListener("visibilitychange", checkForCookie);
  };

  return (
    <div className="rounded-md border border-yellow-400 bg-yellow-50 p-4 mb-4 text-sm">
      <div className="flex flex-row items-center gap-2">
        <div className="rounded-lg bg-yellow-400 h-10 w-10 mr-2 flex items-center justify-center shrink-0">
          <ExclamationTriangleIcon className="h-6 w-6 text-white" />
        </div>
        <div className="flex flex-col grow">
          <div className="text-yellow-500 font-bold">LinkedIn connection issue</div>
          <div className="flex items-center w-full">
            {status === "logged-out" ?
              <>Please reconnect your LinkedIn account to fully enjoy Distill.</>
            : status == "no-extension" ?
              <>
                Please install the Distill Chrome extension, or
                <Link href="/settings" className="ml-1 text-brand-500 hover:underline">
                  update your cookie manually
                </Link>
                .
              </>
            : <>Please reconnect your LinkedIn account to fully enjoy Distill.</>}
          </div>
        </div>
        <div className="flex-1" />
        <div>
          {status === "logged-out" ?
            <>
              <ButtonLink
                href="https://www.linkedin.com/login"
                target="_blank"
                onClick={listenForCookie}
              >
                Log in to LinkedIn
                <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2 text-white" />
              </ButtonLink>
            </>
          : status == "no-extension" ?
            <>
              <ButtonLink
                target="_blank"
                href={`https://chromewebstore.google.com/detail/distill/${PROD_CHROME_EXTENSION}`}
                onClick={listenForCookie}
              >
                Install Extension
                <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-2 text-white" />
              </ButtonLink>
            </>
          : <>
              <div className="flex-1" />
              <ButtonLink href="/setup">Reconnect</ButtonLink>
            </>
          }
        </div>
      </div>
    </div>
  );
}
