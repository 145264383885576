interface Props {
  // From 0 to 1
  progress: number;
  // in px, will be used for both width and height
  size: number;
  color: string;
}

export default function ProgressCircle({ progress, size, color }: Props) {
  if (progress < 0 || progress > 1) {
    throw new Error("Progress must be between 0 and 1");
  }
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        borderRadius: "50%",
        border: `2px solid ${color}`,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          width: "80%",
          height: "80%",
          borderRadius: "50%",
          background:
            progress > 0 ?
              `conic-gradient(${color} 0% ${progress * 100}%, transparent ${progress * 100 + 1}% 100%)`
            : "transparent",
        }}
      ></div>
    </div>
  );
}
