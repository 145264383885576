import Button, { ButtonVariant } from "@/components/ui/Button";
import PopoverMenu from "@/components/ui/PopoverMenu";
import ProgressCircle from "@/components/ui/ProgressCircle";
import ProgressSteps from "@/components/ui/ProgressSteps";
import { pluralize } from "@/lib/stringUtils";
import { uiStore } from "@/stores/uiStore";
import { useStore } from "@nanostores/react";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";

export const MAX_MONTHLY_VISITS = 50;

export default function VisitLimitIcon() {
  const entityVisitCount = useStore(uiStore.entityVisitCount);
  const profileVisits = (entityVisitCount?.visitedIds as string[])?.length;
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (!entityVisitCount || profileVisits === undefined) return null;

  const progressBarSteps = 6;
  const discreteVisitPercentage = Math.floor(
    (profileVisits / MAX_MONTHLY_VISITS) * progressBarSteps,
  );

  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-content={
        popoverOpen ? undefined : (
          `${pluralize(profileVisits, "profile")} viewed this month (of ${MAX_MONTHLY_VISITS})`
        )
      }
      className="mr-2"
    >
      <PopoverMenu
        buttonClass={twJoin("", "w-full")}
        buttonLabel={
          <ProgressCircle
            progress={Math.min((profileVisits || 0) / MAX_MONTHLY_VISITS, 1)}
            size={32}
            color={"grey"}
          />
        }
        onOpen={() => setPopoverOpen(true)}
        onClose={() => setPopoverOpen(false)}
        popperOptions={{ placement: "bottom" }}
      >
        <div className="flex flex-col gap-3 p-5 items-center min-w-80 cursor-default">
          <div className="w-full font-semibold text-md text-left">Profile Views:</div>
          <ProgressSteps
            stepsDone={discreteVisitPercentage}
            stepsLeft={progressBarSteps - discreteVisitPercentage}
            barClassName="h-3"
          />{" "}
          <div className="w-full text-sm text-gray-500 text-left mt-1">
            {profileVisits} out of {MAX_MONTHLY_VISITS} Monthly Profile Views Used
          </div>
          <hr className="mt-4 mb-1 w-full" />
          <div className="hidden">
            <Button className="font-semibold">Connect LinkedIn to View More Profiles</Button>
          </div>
          <div className="text-gray-500">Upgrade to Pro (coming soon)</div>
        </div>
      </PopoverMenu>
    </div>
  );
}
