import { FormEvent, useEffect, useState } from "react";

import useSubmitButton from "@/components/hooks/useSubmitButton";
import TextArea from "@/components/inputs/TextArea";
import TextField from "@/components/inputs/TextField";
import Modal, { ModalBody, ModalButton } from "@/components/modals/Modal";
import { classNames, prettyError } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import {
  ExclamationCircleIcon,
  PencilIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

type Props = {};

export default function InputModal(props: Props) {
  const inputProps = useStore(uiStore.showInputModal);
  const open = !!inputProps;
  const [error, setError] = useState<string | undefined>();

  const { SubmitButton, setSubmitting } = useSubmitButton();

  useEffect(() => {
    setError(undefined);
    setSubmitting(false);
  }, [open, setSubmitting]);
  if (!open) return null;

  const setOpen = (open: boolean) => {
    if (!open) uiStore.showInputModal.set(undefined);
  };

  const type = inputProps?.type || "info";
  const color =
    type == "info" || type == "add" || type == "edit" ? "blue"
    : type == "warning" ? "yellow"
    : "red";

  const Icon =
    type == "add" ? PlusIcon
    : type == "edit" ? PencilIcon
    : type == "info" ? QuestionMarkCircleIcon
    : type == "warning" ? ExclamationCircleIcon
    : XMarkIcon;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const values = inputProps?.fields.map((_, i) => {
      return (form.elements.namedItem(`input${i}`) as HTMLInputElement).value;
    });

    try {
      setSubmitting(true);
      await inputProps?.onSubmit(values || []);
      setOpen(false);
    } catch (e) {
      setError(prettyError(e));
    } finally {
      setSubmitting(false);
    }
  };

  const buttons = (
    <>
      <SubmitButton
        className={
          "py-2 text-sm w-full sm:w-auto justify-center " +
          (color == "blue" ? "bg-blue-600 hover:bg-blue-800"
          : color == "yellow" ? "bg-yellow-600 hover:bg-yellow-800"
          : "bg-red-600 hover:bg-red-800")
        }
      >
        Submit
      </SubmitButton>
      <ModalButton
        onClick={() => {
          setOpen(false);
        }}
        className="bg-gray-600 hover:bg-gray-800"
      >
        Cancel
      </ModalButton>
    </>
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit}>
        <ModalBody
          title={inputProps?.title || ""}
          icon={
            <Icon
              className={classNames(
                "w-6 h-6",
                color == "blue" ? "text-blue-600"
                : color == "yellow" ? "text-yellow-600"
                : "text-red-600",
              )}
            />
          }
          iconBg={
            color == "blue" ? "bg-blue-100"
            : color == "yellow" ?
              "bg-yellow-100"
            : "bg-red-100"
          }
          buttons={buttons}
          className="sm:min-w-[500px]"
        >
          {inputProps?.subtitle && <div className="mt-2 text-gray-600">{inputProps.subtitle}</div>}
          {inputProps?.instructions ?
            <div className="mt-2">{inputProps.instructions}</div>
          : <></>}
          {inputProps?.fields.map((field, i) =>
            field.multiline ?
              <TextArea
                name={`input${i}`}
                defaultValue={field.currentValue}
                key={i}
                rows={6}
                className="w-full mt-4"
                placeholder={field.placeholder}
              />
            : <TextField
                key={i}
                name={`input${i}`}
                className="w-full mt-4"
                placeholder={field.placeholder}
                defaultValue={field.currentValue}
              />,
          )}
          {error && <p className="text-red-600 mt-2 text-sm">{error}</p>}
        </ModalBody>
      </form>
    </Modal>
  );
}
