import { addBusinessDays } from "date-fns";

import { Meeting } from "@/types";

export default function fakeMeetings(startDate?: Date) {
  // tomorrow (falling on business day)
  const today = startDate || new Date();
  const tomorrow = addBusinessDays(today, 1);
  const nextDay = addBusinessDays(today, 2);

  const makeTime = (day: Date, hour: number, minute: number) => {
    const d = new Date(day);
    d.setHours(hour);
    d.setMinutes(minute);
    return d;
  };

  const meetings: Partial<Meeting>[] = [
    {
      id: "fake-1",
      title: "Russ / John 1:1",
      start: makeTime(today, 9, 0),
      end: makeTime(today, 9, 30),
      meta: {
        tags: ["internal", "1:1"],
      },
      attendees: [
        {
          email: "John Mackie <john.mackie@gmail.com>",
        },
      ],
    },
    {
      id: "fake-2",
      title: "Talk about Superhuman's growth strategy",
      start: makeTime(today, 10, 0),
      end: makeTime(today, 10, 45),
      description: "Scheduled with Calendly",
      meta: {
        tags: ["external", "sales"],
      },
      attendees: [
        {
          email: "Rahul Vohra <rahul@superhuman.com>",
        },
      ],
    },
    // {
    //   id: "fake-3",
    //   title: "Distill Product Review",
    //   start: makeTime(today, 13, 0),
    //   end: makeTime(today, 14, 0),
    //   meta: {
    //     tags: ["internal"],
    //   },
    //   attendees: [
    //     {
    //       email: "Sarah Greene <sarah@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-4",
    //   title: "Fundraising Pitch to Galaxy Brain Capital",
    //   start: makeTime(today, 15, 30),
    //   end: makeTime(today, 16, 15),
    //   meta: {
    //     tags: ["external", "fundraising"],
    //   },
    //   attendees: [
    //     {
    //       email: "James Lin <jlin@galaxybrain.vc>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-5",
    //   title: "User Discovery Call with Acme Corp",
    //   start: makeTime(tomorrow, 11, 0),
    //   end: makeTime(tomorrow, 11, 45),
    //   meta: {
    //     tags: ["external", "user-discovery"],
    //   },
    //   attendees: [
    //     {
    //       email: "Alice Smith <asmith@acme.com>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-6",
    //   title: "Interview with Jane Roe, PM Candidate",
    //   start: makeTime(tomorrow, 14, 0),
    //   end: makeTime(tomorrow, 15, 0),
    //   meta: {
    //     tags: ["external", "interview"],
    //   },
    //   attendees: [
    //     {
    //       email: "Jane Roe <candidate@external.com>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-7",
    //   title: "Exec Sync: Q2 Strategy",
    //   start: makeTime(nextDay, 9, 30),
    //   end: makeTime(nextDay, 10, 30),
    //   meta: {
    //     tags: ["internal", "exec"],
    //   },
    //   attendees: [
    //     {
    //       email: "Emily Tran <emily@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-8",
    //   title: "Podcast Interview with 'Tech Today'",
    //   start: makeTime(nextDay, 13, 0),
    //   end: makeTime(nextDay, 14, 0),
    //   meta: {
    //     tags: ["external", "press"],
    //   },
    //   attendees: [
    //     {
    //       email: "Tom Harris <tharris@techtoday.com>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-9",
    //   title: "Sales Call with Salty Peanuts Inc",
    //   start: makeTime(nextDay, 15, 0),
    //   end: makeTime(nextDay, 15, 45),
    //   meta: {
    //     tags: ["external", "sales"],
    //   },
    //   attendees: [
    //     {
    //       email: "Robert Johnson <rjohnson@xyzinc.com>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-10",
    //   title: "Team Alignment Meeting",
    //   start: makeTime(nextDay, 16, 30),
    //   end: makeTime(nextDay, 17, 30),
    //   meta: {
    //     tags: ["internal"],
    //   },
    //   attendees: [
    //     {
    //       email: "Team <team@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-11",
    //   title: "Strategic Partnership Discussion with FinTech Innovations",
    //   start: makeTime(addBusinessDays(today, 3), 10, 0),
    //   end: makeTime(addBusinessDays(today, 3), 11, 0),
    //   meta: {
    //     tags: ["external", "partnership"],
    //   },
    //   attendees: [
    //     {
    //       email: "Mike Chen <mchen@fintechinnovations.com>",
    //     },
    //     {
    //       email: "Lisa Park <lisa@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-12",
    //   title: "Engineering Sync: Platform Scalability",
    //   start: makeTime(addBusinessDays(today, 3), 13, 0),
    //   end: makeTime(addBusinessDays(today, 3), 14, 0),
    //   meta: {
    //     tags: ["internal", "engineering"],
    //   },
    //   attendees: [
    //     {
    //       email: "Alex Johnson <alex@distill.fyi>",
    //     },
    //     {
    //       email: "Danielle Kim <danielle@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-13",
    //   title: "Investor Update Meeting with Greybeard Partners",
    //   start: makeTime(addBusinessDays(today, 4), 9, 30),
    //   end: makeTime(addBusinessDays(today, 4), 10, 15),
    //   meta: {
    //     tags: ["external", "investor"],
    //   },
    //   attendees: [
    //     {
    //       email: "Rebecca Morris <rmorris@greybeard.com>",
    //     },
    //     {
    //       email: "Russ <russ@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-14",
    //   title: "Customer Feedback Session with Beta Users",
    //   start: makeTime(addBusinessDays(today, 4), 11, 0),
    //   end: makeTime(addBusinessDays(today, 4), 12, 0),
    //   meta: {
    //     tags: ["external", "customer"],
    //   },
    //   attendees: [
    //     {
    //       email: "Beta User 1 <user1@external.com>",
    //     },
    //     {
    //       email: "Beta User 2 <user2@external.com>",
    //     },
    //     {
    //       email: "Product Team <product@distill.fyi>",
    //     },
    //   ],
    // },
    // {
    //   id: "fake-15",
    //   title: "Marketing Strategy Brainstorm",
    //   start: makeTime(addBusinessDays(today, 4), 14, 30),
    //   end: makeTime(addBusinessDays(today, 4), 15, 30),
    //   meta: {
    //     tags: ["internal", "marketing"],
    //   },
    //   attendees: [
    //     {
    //       email: "Olivia Sanchez <olivia@distill.fyi>",
    //     },
    //     {
    //       email: "George Brown <george@distill.fyi>",
    //     },
    //   ],
    // },
  ];

  return meetings.map(
    (m, i) =>
      ({
        ...m,
        calendarId: "fake",
        attendees: [
          ...(m.attendees || []),
          {
            email: "Russ Heddleston <russ@distill.fyi>",
            self: true,
          },
        ],
      }) as Meeting,
  );
}
